import React from 'react'

import SEO from '../components/seo'
import LocaleManager from '../components/LocaleManager'
import { FormattedMessage } from 'react-intl'

const NotFoundPage = ({pageContext: { locale }}) => (
  <LocaleManager locale={locale}>
    <FormattedMessage id="404.title">
      {translation => <SEO title={translation} lang={locale} /> }
    </FormattedMessage>
    <div className="content extra-top">
      <div>
      <h3>
        <FormattedMessage id="404.not-found" />
      </h3>
      <p>
        <FormattedMessage id="404.not-found-body" />
      </p>
      </div>
    </div>
  </LocaleManager>
)

export default NotFoundPage
